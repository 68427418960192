import React from 'react'
import Process from '../core/Home/Process'
import WhyUs from '../core/Home/WhyUs'
import Blog from '../core/Home/Blog'
import HomeQuote from '../core/Home/HomeQuote'

function MainCommon() {
  return (
    <div>


      {/* <GetInTouch /> */}
<Process />
      <WhyUs />
      <HomeQuote />
      <Blog />
    </div>
  )
}

export default MainCommon